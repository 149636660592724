/**
 *  Created by pw on 2019-09-19 20:54.
 */
import { visit } from '@ekuaibao/helpers';
export function getDepartmentsData(items) {
    if (items === void 0) { items = []; }
    if (!items.length) {
        return { data: items, mapData: {}, noRootPathMap: {}, list: [] };
    }
    var _a = mapDataFn(items), mapData = _a.mapData, pathMap = _a.pathMap, list = _a.list, noRootPathMap = _a.noRootPathMap;
    return { data: items, mapData: mapData, pathMap: pathMap, list: list, noRootPathMap: noRootPathMap };
}
export function departmentMap(items) {
    var mapData = {};
    visit(items, function (node) {
        mapData[node.id] = node;
    });
    return mapData;
}
function mapDataFn(items) {
    if (items === void 0) { items = []; }
    var mapData = {};
    var noRootPathMap = {};
    var pathMap = {};
    var list = [];
    visit(items, function (node) {
        mapData[node.id] = node;
        list.push(node);
        if (node.parentId && node.parentId.length) {
            var parentPath = pathMap[node.parentId] || '';
            var noRootParentPath = noRootPathMap[node.parentId] || '';
            pathMap[node.id] = parentPath.length ? parentPath + "/" + node.name : node.name;
            noRootPathMap[node.id] = noRootParentPath.length ? noRootParentPath + "/" + node.name : node.name;
        }
        else {
            pathMap[node.id] = node.name;
        }
    });
    return { mapData: mapData, pathMap: pathMap, list: list, noRootPathMap: noRootPathMap };
}
