/**************************************************
 * Created by kaili on 2017/12/5 上午9:50.
 **************************************************/
import { Actions, action, node, reducer } from '@ekuaibao/store'
import { QuerySelect } from 'ekbc-query-builder'
import key, { ID } from './key'
import { app as api } from '@ekuaibao/whispered'
import { ParsePower } from '@ekuaibao/lib/lib/parsePower'
import { Fetch } from '@ekuaibao/fetch'
import { Resource } from '@ekuaibao/fetch'
import { xor } from 'lodash'
import getWorkerResult from './worker-getresult'

//form
const feeTypes = new Resource('/api/v1/form/feeTypes')
const payees = new Resource('/api/v1/form/payees')
const payeeActionV2 = new Resource('/api/pay/v2/payeeinfoconfigs')
const payerActionV2 = new Resource('/api/pay/v2/payerinfoconfigs')
const specifications = new Resource('/api/form/v1/specificationVersions')
const tripTypes = new Resource('/api/trip/v2/type')
//organization
const staffs = new Resource('/api/v1/organization/staffs')
const departments = new Resource('/api/v1/organization/departments')
const externalDepartment = new Resource('/api/v1/organization/externalDepartment')
const role = new Resource('/api/v1/organization/roles')
const roleGroups = new Resource('/api/v1/organization/roleGroups')
const editablefields = new Resource('/api/v1/organization/corporations/editableFields')
const permission = new Resource('/api/v1/organization/permissions')
const delegate = new Resource('/api/v1/organization/delegate')
const temporaryToken = new Resource('/api/account/v2/session/getAccessToken')
//corporationset
const corporationSet = new Resource('/api/v1/corporationset')
//flow
const referables = new Resource('/api/flow/v2/referables')
const backlogs = new Resource('/api/flow/v2/backlogs')
const flows = new Resource('/api/flow/v1/flows')
const property = new Resource('/api/flow/v2/propertySet')
//basedata
const enumItems = new Resource('/api/v1/basedata/enumItems/byEnumCode')
const powers = new Resource('/api/charge/powers')
const budgetMsg = new Resource('/api/v1/budget/report/message/flow')
const loanpackage = new Resource('/api/v1/loan/loanInfo')
const pay = new Resource('/api/pay/v1')
const third = new Resource('/api/ekbmall/v1/third')
const currencyAction = new Resource('/api/v2/currency')
const carbonCopy = new Resource('/api/flow/v2/carbonCopy')
const message = new Resource('/api/message/v1/messages')
const dimensionItems = new Resource('/api/v1/basedata/dimensionItems')
const fieldMap = new Resource('/api/v1/mapping/fieldMapping')
const lastChoice = new Resource('/api/flow/v2/lastChoice')
const setOrganization = new Resource('/api/v2/organization')
const accounts = new Resource('/api/pay/v2/accounts')
const newAccounts = new Resource('/api/v2/pay/accounts')
const assistPlatform = new Resource('/api/assistPlatform/log')
const attachments = new Resource('/api/v1/attachment/attachments')
const spaceAction = new Resource('/api/dingtalk/v2/cspace')
const corgSwitch = new Resource('/api/orgSwitch')
const downloadAction = new Resource('/api/app/download')
const safeSetting = new Resource('/api/v2/corporationset/watermark')
const riskWarning = new Resource('/api/flow/v2/riskwarning')
const condition = new Resource('/api/form/v2/condition')
const delegateAccess = new Resource('/api/v1/organization/delegateSet')
const messageCenterConfig = new Resource('/api/message/v1/messageCenterConfig')
const tradeeditionAction = new Resource('/api/v1/tradeedition')
const exportIsTimeLimit = new Resource('/api/flow/v2/exportIsTimeLimit')
const isWhiteList = new Resource('/api/menu/v2/questionnaires/isWhiteList')
const isUpgrade = new Resource('/api/supplier/v3/corppayment/isUpgrade')


function generateDataKey(data = {}) {
  if (data === null) return ''
  const keys = Object.keys(data)
  const entries = keys.map((key) => [key, data[key] ?? ''])
  entries.sort((a, b) => a[0].localeCompare(b[0]))
  return entries.reduce((result, entry) => result + (entry[0] + '=' + JSON.stringify(entry[1]) + ','), '')
}

const _getStaffPromiseMap = {}

class CommonActions extends Actions {
  static pluginName = ID

  /**
   * 获取已审批代办列表的数量
   * @param state
   * @returns {{payload : *}}
   */
  @reducer((action) => {
    if (action.state === 'APPROVING') {
      return { approving: action.payload.count }
    } else if (action.state === 'SENDING') {
      return { sending: action.payload.count }
    } else if (action.state === 'RECEIVING') {
      return { receiving: action.payload.count }
    }
    return { paying: action.payload.count }
  })
  @node('backlogCount', { approving: 0, paying: 0, sending: 0, receiving: 0 }, true)
  @action
  getBackLogCount(state) {
    let query = new QuerySelect().filterBy(`type!="permit"`)
    if (state === 'APPROVING' && window.__PLANTFORM__ === 'MC') {
      query = query.filterBy(`state.in("${state}")&&(crossCorp==true)`)
    } else {
      query = query.filterBy(`state.in("${state}")`)
    }
    return {
      payload: backlogs.POST('/count', query.value()),
      state: state,
    }
  }

  /**
   * 获取已审批代办列表的数量,合并多个请求优化
   * @param state
   * @returns {{payload : *}}
   */
  @reducer((action) => {
    var defaultValue = { approving: 0, paying: 0, sending: 0, receiving: 0 }
    var payload = JSON.stringify(action.payload || {}).toLocaleLowerCase()
    var data = Object.assign(defaultValue, JSON.parse(payload))
    return data
  })
  @node('backlogCount', { approving: 0, paying: 0, sending: 0, receiving: 0 }, true)
  @action
  getBackLogCountNew(params) {
    return {
      payload: backlogs.GET(`/countBackLogByState/${params}`, {}),
    }
  }

  /**
   * 获取收款人信息
   * @returns {{payload : *}}
   */
  @reducer((action) => action.payload.items)
  @node('payees', [])
  @action
  getPayees(param) {
    return {
      payload: accounts.GET('/mine', {
        start: 0,
        count: 2999,
        filter: 'active == true',
        // join: 'staffId,staffId,/v1/organization/staffs',
        ...param,
      }),
    }
  }

  @reducer((action) => action.payload.value || {})
  @node('defaultPayee')
  @action
  getDefaultPayee() {
    return {
      payload: accounts.GET('/default'),
    }
  }

  /**
 * 获取快速报销消费类型树
 * @param params
 * @returns {{payload : *}}
 */
  @reducer((action) => {
    const tree = action.payload.items || [],
      list = [],
      map = {}
    if (tree.length) {
      const fn = (children) =>
        children.forEach((line) => {
          list.push(line)
          line.children && line.children.length && fn(line.children)
        })
      fn(tree)
      list.forEach((o) => (map[o.id] = o))
    }
    return { data: tree, list, map }
  })
  @node('quickFeetypes', { data: [], list: [], map: {} })
  @action
  getQuickFeetypes(params) {
    return { payload: feeTypes.GET('/quickExpenseList', params) }
  }

  /**
   * 获取消费类型树
   * @param params
   * @returns {{payload : *}}
   */
  @reducer((action) => {
    const tree = action.payload.items || [],
      list = [],
      map = {}
    if (tree.length) {
      const fn = (children) =>
        children.forEach((line) => {
          list.push(line)
          line.children && line.children.length && fn(line.children)
        })
      fn(tree)
      list.forEach((o) => (map[o.id] = o))
    }
    return { data: tree, list, map }
  })
  @node('feetypes', { data: [], list: [], map: {} })
  @action
  getFeeTypes(params) {
    return { payload: feeTypes.GET('/tree/active', params) }
  }

  /**
   * 获取消费全部类型树
   * @param params
   * @returns {{payload : *}}
   */
  @reducer((action) => {
    const tree = action.payload.items,
      list = [],
      map = {}
    if (tree.length) {
      const fn = (children) =>
        children.forEach((line) => {
          list.push(line)
          line.children && line.children.length && fn(line.children)
        })
      fn(tree)
      list.forEach((o) => (map[o.id] = o))
    }
    return { data: tree, list, map }
  })
  @node('feetypesAll', { data: [], list: [], map: {} })
  @action
  getFeeTypesAll(params) {
    const para = {
      ...params,
      join: 'id,feetypeTemplate,/form/v2/specifications/feeType',
      join$2: 'children.id,feetypeTemplate,/form/v2/specifications/feeType',
    }
    return { payload: feeTypes.GET('/tree', para) }
  }

  /**
   * 获取用户信息
   * @param params
   * @returns {{payload, isCheck : boolean}}
   */
  @reducer((action) => {
    const data = action.payload
    const { staff } = data
    if (!staff.corporationId) {
      api.go('/no-org')
    }
    action.isCheck && api.emit(`${key.ID}:user:checked`, data)
    return { data, staff, corporation: staff.corporation }
  })
  @node('userinfo', { data: {} })
  @action
  getUserInfo(params) {
    const data = {
      ...params,
      join: 'staff.corporationId,corporation,/v1/organization/corporations',
      join$1: 'staff.departments,departments,/v1/organization/departments',
      join$2: 'staff.id,autograph,/v1/basedata/autographs',
      join$4: 'staff.id,bankCardNums,/v1/organization/staffs/bankCardNum',
      join$5: 'staff.defaultDepartment,defaultDepartment,/v1/organization/departments',
    }
    /**
     * slRn4Qikh5R9Ug: 广州市中大管理咨询有限公司的企业id
     * PRP-22568 工单PO-20200703-1121
     * 用户登陆企业微信电脑端无法进入，界面显示空白
     * 暂通过取消roles的join解决
     */
    // @i18n-ignore
    if (Fetch.ekbCorpId !== 'slRn4Qikh5R9Ug')
      data['join$3'] =
        'staff.id,roles,/v1/organization/staffs/role?join=values.roleDefId,roleDef,/v1/organization/roles&join=values.properties.项目,project,/v1/basedata/dimensionItems/fullName'

    return {
      payload: staffs.GET('/me', data).then((resp) => resp.value),
      isCheck: params ? params.type === 'check' : false,
    }
  }

  /**
   * 获取已授权激活员工列表
   * @returns {{payload : *}}
   */
  @reducer((action) => action.payload.items)
  @node('staffs', [])
  @action
  getStaffsAuthorized(args) {
    let items = [],
      pages = 1,
      /**
       * slRn4Qikh5R9Ug: 广州市中大管理咨询有限公司的企业id
       * PRP-15313 工单03#4357
       * pageSize > 150时，下面的authorized接口会报空指针异常，影响范围广泛，未找到原因
       * 暂通过改小pageSize解决此hotfix
       */
      pageSize = Fetch.ekbCorpId === 'slRn4Qikh5R9Ug' ? 100 : 3000
    const params = {
      start: 0,
      count: pageSize
    }
    const TEST_CORP = ["VRm3Kg2sYl0biL", "uzU7pcWfScck00", "ID_3qzOi9p0oEM"]
    if (!args?.isRole && !TEST_CORP.includes(Fetch.ekbCorpId)) {
      params.join = 'id,roles,/v1/organization/staffs/role?join=values.roleDefId,roleDef,/v1/organization/roles'
    }
    const getStaffsFn = () => {
      const hiddenLoading = !!api.getState()['@common']?.staffs?.length
      return staffs.GET('/authorized', params, null, { hiddenLoading }).then(async (resp) => {
        if (resp.count !== 0) {
          items = items.concat(resp.items)
          params.start = pages * pageSize
          pages++
          return getStaffsFn()
        }
        await api.dispatch(this.authStaffDepartmentStaffMap(items))
        return { items }
      })
    }

    return { payload: getStaffsFn() }
  }

  @reducer((action) => action.payload)
  @node('authStaffDepartmentStaffMap', {})
  @action
  authStaffDepartmentStaffMap(staffs) {
    const parseStaffsToStaffDepartMap = (staffs = []) => {
      const departmentStaffMap = {}
      if (!staffs?.length) {
        return departmentStaffMap
      }
      staffs.forEach((staff) => {
        staff.departments.forEach((deptId) => {
          let staffObj = departmentStaffMap[deptId]
          if (!staffObj) {
            staffObj = { staffList: [], staffMap: {} }
            departmentStaffMap[deptId] = staffObj
          }
          if (!staffObj.staffMap[staff.id]) {
            staffObj.staffList.push(staff)
            staffObj.staffMap[staff.id] = staff
          }
        })
      })
      return departmentStaffMap
    }
    return { payload: parseStaffsToStaffDepartMap(staffs) }
  }

  /**
   * 新获取已授权激活员工列表（支持分页）
   * @returns {{payload : *}}
   */
  @reducer((action) => ({
    items: action.payload.items,
    count: action.payload.count,
  }))
  @node('staffsData')
  @action
  getStaffsAuthorizedAssist(data) {
    !data && (data = { start: 0, count: 20, departmentId: '' })
    const params = {
      ...data,
      // @i18n-ignore
      join:
        'id,roles,/v1/organization/staffs/role?join=values.roleDefId,roleDef,/v1/organization/roles',
    }

    return { payload: staffs.GET('/authorized/assist', params) }
  }

  /**
   * 获取可见性员工列表
   * 普通用户可见的界面：包括
   单据中选择的部门架构（选人组件、选部门组件），和评论艾特功能的架构，以及委托授权的架构（按提交人的权限），还有审批流选择人员、转交审批，申请事项的转交、共享
   * @returns {{payload : *}}
   */
  @reducer((action) => action.payload.items)
  @node('staffsVisibility', [])
  @action
  getVisibilityStaffsAuthorized(staffId, args) {
    let items = [],
      pages = 1,
      /**
       * slRn4Qikh5R9Ug: 广州市中大管理咨询有限公司的企业id
       * PRP-15627 工单03#4357
       * pageSize > 150时，下面的authorized接口会报空指针异常，影响范围广泛，未找到原因
       * 暂通过改小pageSize解决此hotfix
       */
      pageSize = Fetch.ekbCorpId === 'slRn4Qikh5R9Ug' ? 100 : 3000
    //pageSize = 1000
    const params = {
      start: 0,
      count: pageSize,
      // @i18n-ignore
      join$1: 'defaultDepartment,defaultDepartment,/v1/organization/departments',
      staffId,
    }
    const TEST_CORP = ["VRm3Kg2sYl0biL", "uzU7pcWfScck00", "ID_3qzOi9p0oEM"]
    if (!args?.isRole && !TEST_CORP.includes(Fetch.ekbCorpId)) {
      params.join = 'id,roles,/v1/organization/staffs/role?join=values.roleDefId,roleDef,/v1/organization/roles'
    }

    const getVisibilityStaffsFn = () => {
      return staffs.GET('/visibility/authorized', params).then((resp) => {
        items = items.concat(resp.items)
        if (resp.items.length !== 0) {
          params.start = pages * pageSize
          pages++
          return getVisibilityStaffsFn()
        }
        return { items }
      })
    }
    return { payload: getVisibilityStaffsFn() }
  }

  /**
   * 获取全部已激活员工列表
   * @returns {{payload : *}}
   */
  @reducer((action) => ({
    data: action.payload.items,
    count: action.payload.count,
  }))
  @node('staffsActives', { data: [] })
  @action
  getStaffsActives(ags) {
    return this.getStaff(ags)
  }

  /**
   * 根据条件获取员工列表
   * params:{range:'all\true\false'}
   * @returns {{payload : *}}
   */
  @reducer((action) => ({
    data: action.payload.items,
    count: action.payload.count,
  }))
  @node('rangeStaffs', { data: [] })
  @action
  getRangeStaffs(params) {
    return this.getStaff(params)
  }

  /**
   * 获取限制范围内的人员
   * @returns {{payload : *}}
   */
  @reducer((action) => ({
    data: action.payload.items,
    count: action.payload.count,
  }))
  @node('staffRangeByRule', { data: [] })
  @action
  getStaffRangeByRule(ags) {
    return this.getStaffByRule(ags)
  }
  //根据人员可选范围id获取人员
  getStaffByRule(data = {}) {
    let items = []
    let pages = 1
    const pageSize = 2999
    const params = {
      start: 0,
      count: pageSize,
      ...data,
    }
    const getStaffsByRule = () => {
      return condition.POST('/getStaffList/$ruleId', params).then((resp) => {
        items = items.concat(resp.items)
        if (resp.items.length === pageSize) {
          params.start = pages * pageSize
          pages++
          return getStaffsByRule()
        }
        return { items }
      })
    }
    return { payload: getStaffsByRule() }
  }

  getStaff(data = {}) {
    const dataKey = generateDataKey(data)
    if (_getStaffPromiseMap[dataKey]) return { payload: _getStaffPromiseMap[dataKey]};

    const getStaffs = async () => {
      let items = []
      let pages = 1
      const pageSize = 2999
      const params = {
        start: 0,
        count: pageSize,
        ...data,
      }
      let resp
      while (true) {
        resp = await staffs.GET('/actives', params)
        items = items.concat(resp.items)
        if (resp.items.length !== pageSize) {
          break
        }
        params.start = pages * pageSize
        pages++
      }
      return { items }
    }

    const promise = getStaffs()
    _getStaffPromiseMap[dataKey] = promise
    promise.finally(() => {
      _getStaffPromiseMap[dataKey] = undefined
    })
    return { payload: promise }
  }

  @reducer((action) => action.payload.items)
  @action
  getStaffsByIds(params) {
    return { payload: staffs.GET('/[ids]', params) }
  }

  /**
   * 获取已同步未授权员工列表
   * @returns {{}}
   */
  @reducer((action, state) => {
    const staffsAuthorized = state.staffs.map((el) => el.id)
    const staffsAll = state.staffsActives.data.map((el) => el.id)
    return xor(staffsAuthorized, staffsAll)
  })
  @node('staffsBlacklist', [])
  @action
  getStaffsDisabledUsers() {
    return {}
  }

  @reducer((action) => action.payload.items)
  @node('staffsAdmin', [])
  @action
  getStaffsByPermission(data) {
    const params = { ...data, name: 'SYS_ADMIN' }
    return { payload: staffs.GET('/byPermission', params) }
  }

  @reducer(function (action, state) {
    if (action.typeName === 'worker') {
      return { ...action.data }
    } else {
      const data = action.payload.items
      data.length
        ? api.dispatch(
          this.getDepartmentTree('worker', getWorkerResult('getDepartmentsData', data)),
        )
        : null

      return { data, noRootPathMap: {}, pathMap: {}, list: [], mapData: {}, ...state.department }
    }
  })
  @node('department', {})
  @action
  getDepartmentTree(typeName, data) {
    return typeName ? { typeName, data } : { payload: departments.GET('/tree/actives') }
  }

  // 获取新的部门接口（支持分页）
  @reducer(function (action) {
    if (action.typeName === 'worker') {
      return { ...action.data }
    } else {
      const data = action.payload.items
      data.length
        ? api.dispatch(
          this.getDepartmentTreeNew('worker', getWorkerResult('getDepartmentsData', data)),
        )
        : null

      let treeData = []
      treeData = data.map((item) => {
        item.children = []
        return item
      })
      return { data: treeData, noRootPathMap: {}, pathMap: {}, list: [], mapData: {} }
    }
  })
  @node('departmentNew', { data: [] })
  @action
  getDepartmentTreeNew(typeName, data, params = {}) {
    const { start = 0, count = 50, parentId = '' } = params
    const paramsData = {
      limit: {
        start,
        count,
      },
      filterBy: '(active == true)',
    }
    const query = {
      corpId: Fetch.corpId,
      accessToken: Fetch.accessToken,
      parentId,
    }
    return typeName
      ? { typeName, data }
      : { payload: departments.POST(`/tree/flat/$cordId`, paramsData, query), params }
  }

  // 获取部门搜索结果
  @reducer()
  @node('searchDepartmentData', [])
  @action
  getSearchDepartment(params) {
    const filterBy = `(active == true && (name.contains(\"${params.searchText}\")||code.contains(\"${params.searchText}\")))`
    const query = { filterBy }
    return { payload: departments.POST('/search/assist', query) }
  }

  @reducer()
  @node('costCenter', {})
  @action
  getCostCenterById(costCenterId) {
    return { payload: dimensionItems.GET('/[costCenterId]', { costCenterId }) }
  }

  @reducer()
  @node('dimensionItemValue', {})
  @action
  getDimensionValueById(params) {
    return { payload: dimensionItems.GET('/$id', params) }
  }

  @reducer(function (action) {
    if (action.typeName === 'worker') {
      return { ...action.data }
    } else {
      const data = action.payload.items
      data.length
        ? api.dispatch(
          this.getExternalDepartmentTree('worker', getWorkerResult('getDepartmentsData', data)),
        )
        : api.dispatch(this.getExternalDepartmentTree('init'))
      if (action.typeName === 'init') {
        api.dispatch(actions.getExternalList())
        return { ...action.data }
      }
      return { data, noRootPathMap: {}, pathMap: {}, list: [], mapData: {} }
    }
  })
  @node('externalDepartment', {})
  @action
  getExternalDepartmentTree(typeName, data) {
    if (typeName === 'init') {
      return { typeName, payload: externalDepartment.POST('/initDept') }
    } else {
      return typeName ? { typeName, data } : { payload: externalDepartment.GET('/tree') }
    }
  }

  @reducer()
  @node()
  @action
  getDepartmentById(ids) {
    return { payload: departments.GET('/withFullName/$[ids]', { ids }) }
  }

  @reducer(function (action) {
    if (action.typeName === 'worker') {
      return { ...action.data }
    } else {
      const data = action.payload.items
      data.length
        ? api.dispatch(
          this.getVisibilityDepartmentTree(
            null,
            'worker',
            getWorkerResult('getDepartmentsData', data),
          ),
        )
        : null
      return { data, noRootPathMap: {}, pathMap: {}, list: [], mapData: {} }
    }
  })
  @node('departmentVisibility', {})
  @action
  getVisibilityDepartmentTree(staffId, typeName, data) {
    return typeName
      ? { typeName, data }
      : { payload: departments.GET('/tree/visibility/actives', { staffId }) }
  }

  @reducer((action) => action.payload.items)
  @action
  getDeptById(id) {
    return { payload: departments.GET('/$id', { id }) }
  }

  @reducer((action) => action.payload.items)
  @node('roleList', [])
  @action
  getRoleList(data) {
    const params = { start: 0, count: 2999, ...data }
    return { payload: role.GET('', params) }
  }

  @reducer((action) => action.payload.items)
  @node('roleGroupList', [])
  @action
  getRoleGroups() {
    return { payload: roleGroups.GET() }
  }

  @reducer((action) => action.payload.items[0] || {})
  @node('budgetMsg', {})
  @action
  getBudgetMsg(ids) {
    return { payload: budgetMsg.GET('/[ids]', { ids }) }
  }

  @reducer((action) => action.payload.items)
  @node('delegators', [])
  @action
  getDelegators() {
    const data = {
      join$1: 'defaultDepartment,defaultDepartment,/v1/organization/departments',
    }
    return { payload: delegate.GET('/byDelegateeId', data) }
  }

  @reducer((action) => action.payload)
  @node('loanPackage', {})
  @action
  listLoanPackageBySubmitter(param) {
    const { state = 'REPAID', start = 0, count = 999, filters = '', flowId, id, method = 'get' } =
      param || {}

    if (method === 'get') {
      return {
        payload: loanpackage.GET('/byOwnerIdAndState/$id', param, null, { hiddenLoading: true }),
      }
    }

    const query = new QuerySelect()
      .limit(start, count)
      .filterBy(`state.in("${state}")`)
      .filterBy(filters)
      .value()

    return {
      payload: loanpackage.POST('/byOwnerIdAndState/$id/search', { id, flowId, ...query }),
    }
  }

  @reducer((action) => action.payload)
  @action
  getDimensionById(param) {
    return { payload: dimensionItems.POST('/byEntity/$id/search', param) }
  }

  @reducer((action) => {
    //为数据中的元素添加fullPath
    function addFullPath(list = [], fullPathName = '') {
      list.forEach((el) => {
        el.fullPath = fullPathName ? `${fullPathName}/${el.name}` : el.name
        if (el.children) {
          el.children = addFullPath(el.children, el.fullPath)
        }
        return el
      })
      return list
    }
    addFullPath(action.payload.items)
    return action.payload
  })
  @node('dimensionValue', {})
  @action
  getDimensionItemsByPropertyId(param) {
    const { withVisibility = true, ...others } = param
    return {
      id: name,
      payload: referables.GET(`/$name`, { withVisibility, ...others }),
    }
  }

  @reducer((action) => action.payload.items)
  @node('editableFields', [])
  @action
  getEditableFields() {
    return { payload: editablefields.GET() }
  }

  /**
   * 获取支付账户相关接口
   * @param active
   * @returns {{active : boolean, payload : *}}
   */
  @reducer((action, state) => {
    const {
      payload: { items },
      active,
    } = action

    let list = items
    if (!state.powers.Wallet) {
      list = items.filter((o) => o.sort !== 'WALLET')
    }

    if (!active) {
      return { list }
    }
    return { activeList: list }
  })
  @node('payAccount', { list: [], activeList: [] }, true)
  @action
  getPayAccountList(active = false, data) {
    const filter = active ? '(asPayer==true&&active==true)' : 'asPayer==true'
    const params = {
      start: 0,
      count: 2999,
      filter,
      // join: 'staffId,staffId,/v1/organization/staffs'
    }
    if (data) {
      params.flowIds = [data.id]
    }
    return {
      active,
      payload: data ? newAccounts.POST('/actives/filter', params) : accounts.GET('', params),
    }
  }

  /**
   * 获取支付账户相关新接口
   * @param filter
   * @returns {{payload : *}}
   */
  @reducer((action) => {
    const {
      payload: { items, count },
      active,
    } = action

    let list = items
    if (!api.getState()['@common'].powers.Wallet) {
      list = items.filter((o) => o.sort !== 'WALLET')
    } else {
      const isOpened = list.filter((o) => o.sort === 'WALLET').length > 0
      if (!isOpened) {
        list.unshift({
          accountName: '企业钱包',
          sort: 'WALLET',
          isOpened: !isOpened,
          active: true,
        })
      }
    }

    if (!active) {
      return { list, count }
    }
    return { activeList: list }
  })
  @node('newPayAccount', { list: [], activeList: [] }, true)
  @action
  getNewPayAccountList(filter = { asPayer: true, start: 0, count: 600 }) {
    const url = `?filter=(asPayer == ${filter.asPayer})&start=${filter.start}&count=${filter.count}`
    return { payload: accounts.GET(url) }
  }

  /**
   * 获取当前登录人可使用的支付列表
   *
   */
  @reducer((action) => action.payload.items)
  @node('payAccountsVisibilityList', [])
  @action
  getPayAccountsVisibilityList() {
    return { payload: pay.GET('/accounts/visibility') }
  }

  @reducer((action) => action.payload.items)
  @action
  getFullName(ids) {
    return { payload: dimensionItems.POST(`/fullName`, { ids }) }
  }

  @reducer((action) => action.payload.items)
  @action
  getFullNameById(ids) {
    return { payload: dimensionItems.POST(`/fullName/byId`, { ids }) }
  }

  @reducer((action) => action.payload.items)
  @action
  getFullNameById(ids) {
    return { payload: dimensionItems.POST(`/fullName/byId`, { ids }) }
  }

  @reducer((action) => action.payload.items)
  @node('provinceList', [])
  @action
  getProvinceList() {
    return { payload: pay.GET('/banks/provinces') }
  }

  @reducer((action) => action.payload.items)
  @node('cityList', [])
  @action
  getCityList(data) {
    return { payload: pay.GET('/banks/cities', data) }
  }

  @reducer((action) => action.payload.items)
  @node('branchList', [])
  @action
  getBranchList(data) {
    return { payload: pay.GET('/banks/branches', data) }
  }

  @reducer((action) => action.payload.items)
  @node('bankList', [])
  @action
  getBankList(data) {
    return { payload: pay.GET('/banks/banks', data) }
  }

  @reducer((action) => action.payload.items)
  @node('channelList', [])
  @action
  getChannelList() {
    return { payload: pay.GET('/accounts/corporationChannels') }
  }

  @reducer((action) => action.payload.items)
  @node('payerInfo', [])
  @action
  getPayerInfo(param = {}) {
    const data = {
      join: 'visibility.staffs,staffs,/v1/organization/staffs',
      join$1: 'visibility.departments,departments,/v1/organization/departments',
      join$2: 'visibility.roles,roles,/v1/organization/roles',
    }
    return { payload: corporationSet.GET('/payerinfo', { ...data, ...param }) }
  }

  /**
   * 获取全局字段列表
   * @returns {{payload : *}}
   */
  @reducer((action) => {
    let data = action.payload.items
    if (!data) {
      return {
        data: [],
        extension: [],
        preset: [],
        apportionField: {},
        splitCalField: {},
        baseDataPropertiesMap: {},
      }
    }
    const extension = data.filter((v) => !v.ability)
    const preset = data.filter((v) => v.ability)
    const apportion = preset.filter((v) => v.ability === 'apportion')
    const apportionField = preset.find((o) => o.name === 'apportions')
    const splitCalField = preset.find((o) => o.name === 'splitCalculation')
    const checkingBillField = preset.find((o) => o.name === 'checkingBillForm')
    const flowLinksField = extension.find((o) => o.name === 'flowLinks')
    const invoiceFormField = preset.find((o) => o.name === 'invoiceForm')
    data = data.filter((item) => item.name !== 'payPlanMode')
    const baseDataPropertiesMap = {}
    data.forEach((line) => (baseDataPropertiesMap[line.name] = line))
    return {
      data,
      extension: extension?.filter((v) => v?.name !== 'flowLinks') ?? [],
      preset,
      apportion,
      baseDataPropertiesMap,
      apportionField,
      splitCalField,
      checkingBillField,
      invoiceFormField,
      flowLinksField,
    }
  })
  @node('globalFields', {
    data: [],
    extension: [],
    preset: [],
    apportion: [],
    apportionField: {},
    splitCalField: {},
    baseDataPropertiesMap: {},
  })
  @action
  getBaseDataProperties() {
    return { payload: property.GET('') }
  }

  //#region PowerList

  @reducer((action) => {
    const items = action.payload.items
    const { powerMap, powerCodeMap } = ParsePower(items)
    return {
      ...powerMap,
      powerCodeMap,
      powersList: items,
    }
  })
  @node('powers', {}, true)
  @action
  getPowers() {
    return { payload: powers.GET() }
  }

  //#endregion

  @reducer((action) => action.payload.items)
  @node('permissions', [])
  @action
  getTotalPermission() {
    return { payload: permission.GET('') }
  }

  @reducer
  @action
  getPayeeChangeLog(id) {
    const params = { id, join: 'operatorId,operator,/v1/organization/staffs' }
    return { payload: payees.GET('/$id/log', params) }
  }

  /**
   * 获取上传地址，覆盖默认地址
   * @returns {{payload : *}}
   */

  @reducer((action) => {
    const { value } = action.payload
    const { uploadServiceUrl } = value || {}
    if (uploadServiceUrl) {
      window.UPLOAD_INVOICE_FILE_URL = uploadServiceUrl
    }
    return action.payload.value
  })
  @node('uploadServiceUrl', {})
  @action
  getEnvironment() {
    return { payload: corporationSet.GET('/environment') }
  }

  @reducer
  @action
  getAttachmentToken() {
    return { payload: attachments.GET('/token') }
  }

  @reducer
  @action
  getFlowInfoById({ id, privilegeId = '', flowManager }) {
    const { joinParams, selectEntity } = api.require('@lib/entity.join')
    const { fetchAttachment, fetchInvoice } = api.require('@lib/attachment-fetch')
    const fetchMutilValue = api.require('@lib/mutil-staff-fetch')

    const query = new QuerySelect().filterBy(`id=="${id}"`).select(`${selectEntity()}`).value()
    const params = { id, privilegeId, ...joinParams() }
    if (flowManager) params.flowManager = true
    return {
      payload: flows
        .POST('/flowId/$id', query, params)
        .then(fetchAttachment)
        .then(fetchInvoice)
        .then(fetchMutilValue),
    }
  }

  @reducer
  @action
  getEnumItems(enumCode) {
    return { payload: enumItems.GET(`/$code`, { code: enumCode }) }
  }

  @reducer((action) => action.payload.items)
  @node('apportionSpecifications', [])
  @action
  getApportionTemplates() {
    return { payload: specifications.GET('/actives', { type: 'apportion' }) }
  }

  @reducer((action) => action.payload.value.open)
  @node('isHaveOfficialCardPower')
  @action
  getCorpOfficialCard() {
    return { payload: third.GET('/account/isOpen/$code', { code: '120203' }) }
  }

  @reducer((action) => {
    const { value = {} } = action.payload
    window.CURRENCY_SYMBOL = value.symbol || i18n.get('￥')
    window.CURRENCY_STRCODE = value.strCode || 'CNY'
    return value
  })
  @node('standardCurrency', {})
  @action
  getStandardCurrency() {
    return { payload: currencyAction.GET('/standard') }
  }

  /**
   * 法人实体多本位币列表
   */

  @reducer((action) => {
    const { items = [] } = action.payload
    return items
  })
  @node('allStandardCurrency', [])
  @action
  getAllStandardCurrency() {
    return { payload: currencyAction.GET('/legalEntity/standards') }
  }

  @reducer((action) => action.payload.items)
  @node('allCurrencyRates', [])
  @action
  getEnterpriseAllCurrencyRate() {
    return { payload: currencyAction.GET('/active') }
  }

  @reducer((action) => action.payload.value)
  @node('currencyConfig', {})
  @action
  getCurrencyConfig() {
    return { payload: currencyAction.GET('/config') }
  }

  @reducer((action) => action.payload.value)
  @node('payeeConfig', {})
  @action
  payeeConfig() {
    return { payload: payeeActionV2.GET('') }
  }

  @reducer((action) => action.payload.value)
  @node('payerConfig', {})
  @action
  payerConfig() {
    return { payload: payerActionV2.GET('') }
  }

  @reducer((action) => action.payload)
  @node('dimensionItems', {})
  @action
  dimensionItems(items = {}) {
    return { payload: items }
  }

  @reducer((action, state) => {
    const { items, count } = action.payload
    const messageTotal = action.read ? state.message.messageTotal : count
    const messageReadedTotal = action.read ? count : 0
    return { messageList: items, messageTotal, messageReadedTotal }
  })
  @node('message', { messageList: [], messageTotal: 0 })
  @action
  messageList(param) {
    return { payload: message.GET('', param), read: param.read }
  }

  @reducer
  @action
  updateMessageStatus(id) {
    return { payload: message.PUT('/$id', { id }) }
  }

  @reducer((action) => action.payload.items)
  @node('fullTripTypes', [])
  @action
  getFullTripTypes() {
    const query = new QuerySelect().orderBy('createTime', 'ASC').value()
    return {
      payload: tripTypes.POST('/search', query),
    }
  }

  @reducer((action) => action.payload)
  @node('carbonCopyCount', {})
  @action
  getCarbonCopyCount() {
    const query = new QuerySelect()
      .filterBy(`type!="permit"`)
      .filterBy(`state.in("${'UNREAD'}")`)
      .value()
    return { payload: carbonCopy.POST('/count', query) }
  }

  @reducer((action) => action.payload)
  @node('mallRole', {})
  @action
  getMallRole() {
    return { payload: messageCenterConfig.GET('/getMallRole') }
  }

  @reducer((action) => {
    return action.payload
  })
  @node('fieldMap', {})
  @action
  getFieldMaps(sourceTotarget) {
    const id = `${Fetch.ekbCorpId}:${sourceTotarget}`
    return { payload: fieldMap.GET('/$id', { id }) }
  }

  @reducer((action) => {
    const items = action.payload.items || []
    const choiceValue = items.length > 0 ? items[0].choiceValue : []
    return { choiceValue }
  })
  @node('lastChoice', {})
  @action
  getLastChoice(userId) {
    return { payload: lastChoice.GET('/$id', { id: userId }) }
  }

  @reducer((action) => action.payload)
  @node('CertificateTypeList', [])
  @action
  getCertificateTypeList() {
    return { payload: enumItems.GET('/$id', { id: 'CertificateType' }) }
  }

  @reducer((action) => action.payload.items)
  @node('externalList', [])
  @action
  getExternalList() {
    const params = {
      start: 0,
      count: 2999,
      external: true,
      range: true,
    }
    return this.getStaff(params)
  }

  @reducer
  @action
  getTemporaryToken(expireDate) {
    return { payload: temporaryToken.GET('', { expireDate }) }
  }

  @reducer
  @action
  readAllUnReadMessage() {
    return { payload: message.PUT('/all') }
  }

  @reducer((action) => action.payload.items)
  @node('branchByCityIdList', [])
  @action
  getBranchByCityIdList(data) {
    return { payload: pay.GET('/banks/branchesByCityId', data) }
  }

  @reducer((action) => action.payload)
  @node('dimensionList', [])
  @action
  getDimensionList(param) {
    return { payload: dimensionItems.GET('/byDimensionId/$id/active', param) }
  }

  @reducer((action) => action.payload.items)
  @node('unionBanksList', [])
  @action
  getUnionBanksList(data) {
    return { payload: pay.GET('/banks/getUnionBanks', data) }
  }

  /**
   * 获取全局获取通讯录配置
   * @returns {{payload : *}}
   */
  @reducer((action) => action.payload.value)
  @node('organizationConfig', {})
  @action
  getOrganizationConfig() {
    return { payload: setOrganization.GET('/config') }
  }

  //员工信息
  @reducer
  @action
  getStaffsById(params) {
    const data = {
      ...params,
      // @i18n-ignore
      join:
        'id,roles,/v1/organization/staffs/role?join=values.roleDefId,roleDef,/v1/organization/roles&join=values.properties.项目,project,/v1/basedata/dimensionItems/fullName',
      join$1: 'id,bankCardNums,/v1/organization/staffs/bankCardNum',
    }
    return { payload: staffs.GET('/$id', data) }
  }

  @reducer((action) => action.payload.value)
  @node('specificationVersionConfig', { hideVersion: false })
  @action
  getSpecificationVersionedConfig() {
    return { payload: specifications.GET('/config') }
  }

  @reducer((action) => action.payload.value)
  @node('assits', null)
  @action
  getAssistRecode(data) {
    return { payload: assistPlatform.POST('/save', data) }
  }

  @reducer((action) => action.payload.id)
  @node('spaceId')
  @action
  getSpaceId() {
    return { payload: spaceAction.GET('/spaceid') }
  }

  @reducer((action) => action.payload.id)
  @node('dingPanAuthorize')
  @action
  dingPanAuthorize() {
    return { payload: spaceAction.GET('/authorize/add') }
  }

  @reducer((action) => action.payload.id)
  @node('dingPanAuthorizeDownload')
  @action
  dingPanAuthorizeDownload(param = {}) {
    return { payload: spaceAction.GET('/authorize/download/[fileIds]', param) }
  }

  @reducer((action) => action.payload.value)
  @node('MCPermission', null)
  @action
  getMCPermission(permissionName) {
    return { payload: permission.GET('/query', { model: permissionName }) }
  }

  @reducer((action) => {
    const { errorCode } = action.payload
    if (errorCode === 200) {
      return true
    }
  })
  @node('standaloneCorpState', null)
  @action
  getStandaloneCorpState() {
    return { payload: corgSwitch.GET('') }
  }

  @reducer((action) => action.payload)
  @node('dimensionFullPathMapForApportion', null)
  @action
  saveDimensionFullPathMap(fullPathMap) {
    return { payload: fullPathMap }
  }

  @reducer((action) => action.payload.value)
  @node('appDownloadUrl')
  @action
  getAppDownloadUrl() {
    return { payload: downloadAction.GET('/getUrls') }
  }

  @reducer((action) => action.payload.value)
  @node('waterMark', null)
  @action
  getWaterMark() {
    return { payload: safeSetting.GET('/getText') }
  }

  @reducer((action) => action.payload.value)
  @node('warningText', null)
  @action
  getWarningText() {
    return { payload: riskWarning.GET('/warningText') }
  }

  @reducer((action) => action.payload.value)
  @node('exportIsTimeLimit', null)
  @action
  exportIsTimeLimit() {
    return { payload: exportIsTimeLimit.POST('') }
  }
  /**
   * 获取委托审批已授权员工列表
   * @returns {{payload : *}}
   */
  @reducer((action) => action.payload.items)
  @node('delegateStaffs', [])
  @action
  getDelegateStaffsAuthorized(delegateType) {
    let items = [],
      pages = 1,
      /**
       * slRn4Qikh5R9Ug: 广州市中大管理咨询有限公司的企业id
       * PRP-15313 工单03#4357
       * pageSize > 150时，下面的authorized接口会报空指针异常，影响范围广泛，未找到原因
       * 暂通过改小pageSize解决此hotfix
       */
      pageSize = Fetch.ekbCorpId === 'slRn4Qikh5R9Ug' ? 100 : 3000
    if (PLATFORMINFO?.platform === 'huayu') {
      pageSize = 1000
    }
    const params = {
      start: 0,
      count: pageSize,
      // @i18n-ignore
      // join:
      //   'id,roles,/v1/organization/staffs/role?join=values.roleDefId,roleDef,/v1/organization/roles',    //华宇太慢了 join 数据也没啥用
      delegateType: delegateType,
    }
    const getDelegateStaffsFn = (delegateType) => {
      return delegateAccess.GET('/staffs/$delegateType', params).then((resp) => {
        if (resp.count != 0) {
          items = items.concat(resp.items)
          params.start = pages * pageSize
          params.delegateType = delegateType
          pages++
          return getDelegateStaffsFn(delegateType)
        }
        return { items }
      })
    }

    return { payload: getDelegateStaffsFn(delegateType) }
  }

  @reducer((action) => action.payload.items)
  @node('getCurrencyAll', [])
  @action
  getCurrencyAll() {
    return { payload: enumItems.GET('/$currency', { currency: 'currency' }) }
  }

  @reducer((action) => action.payload.value)
  @node('standardLimtConfig')
  @action
  getStandardLimtConfig() {
    return { payload: tradeeditionAction.GET('/limitConfig') }
  }

  @reducer((action) => action.payload.value)
  @node('isWhiteList')
  @action
  getIsWhiteList() {
    return { payload: isWhiteList.GET('') }
  }

  @reducer((action) => action.payload.value)
  @node('isUpgrade')
  @action
  getIsUpgrade() {
    return { payload: isUpgrade.POST('') }
  }
}

export const actions = new CommonActions()
export const commonActions = actions

export default actions
