import { app as api } from '@ekuaibao/whispered'
import Cache from '@ekuaibao/lib/lib/cache'
import actions from './common-actions'
import { checkBrowserIsChrome } from '@ekuaibao/lib/lib/lib-util'
import { showNotification, showMessage } from '@ekuaibao/show-util'
import { Fetch } from '@ekuaibao/fetch'
import * as Sentry from '@sentry/browser'

const getVisibilityStaffsAuthorizedRequestThrottleMap = {}

// 具备防抖功能的 getStaffsAuthorized 请求
let getStaffsAuthorizedPromise = undefined
const getStaffsAuthorizedThrottled = () => {
  if (getStaffsAuthorizedPromise) return getStaffsAuthorizedPromise;
  getStaffsAuthorizedPromise = api.dispatch(actions.getStaffsAuthorized())
  getStaffsAuthorizedPromise.finally(() => {
    getStaffsAuthorizedPromise = undefined
  })
  return getStaffsAuthorizedPromise
}



export default [
  {
    id: '@common',
    reducer: () => Promise.resolve(actions.getReducer()),

    async onfirst() {
      api.on(`@common:user:checked`, (user) => {
        if (user.staff.corporationId) {
          api.invokeService(`@common:fetch:baseData`)
          //初始化神策埋点功能
          window.TRACK && window.TRACK.init && window.TRACK.init(user.staff)
        }
      })
      if (window.__PLANTFORM__ === 'KD_CLOUD' && !checkBrowserIsChrome()) {
        showNotification.info(
          i18n.get(
            '系统检测到你正在使用的浏览器可能无法稳定运行易快报，建议使用chrome浏览器或360极速浏览器',
          ),
          null,
        )
      }
      // 首次检测需要刷新 globalFields
      api
        .dataLoader('@common.userinfo')
        .load({ type: 'check' })
        .then(async () => {
          api.dataLoader('@common.globalFields').load()

          // 配置sentry基本信息
          const userinfo = api.getState()['@common'].userinfo
          if (userinfo) {
            const { id = '', userId = '', name = '', corporation = {} } = userinfo.staff
            Sentry.configureScope((scope) => {
              scope.setUser({
                id: userId,
                username: name,
              })
              scope.setTag('corpId', corporation.id || '')
              scope.setTag('corName', corporation.name || '')
              scope.setTag('staffId', id)
            })
          }
        })
      api.invokeService('@common:get:mall:role')
      api.invokeService('@common:get:payeeinfos')
      api.invokeService('@common:get:organization:config')
      await api.invokeService('@audit:get:dynamic:channel')
      await api.dataLoader('@common.getCurrencyAll').load()
      await api.dataLoader('@common.isWhiteList').load()
      //原生私有化部署下 拉取是否可切换企业的权限
      window.__PLANTFORM__ === 'APP' &&
        IS_STANDALONE &&
        api.dispatch(actions.getStandaloneCorpState())
    },
    'refresh:userinfo'() {
      return api.dispatch(actions.getUserInfo({ type: 'refresh' }))
    },
    'get:userinfo'(params) {
      return api.dispatch(actions.getUserInfo(params))
    },
    'get:staffs:roleList:department'(params) {
      return Promise.all([
        api.dataLoader('@common.staffs').load(params),
        api.dataLoader('@common.roleList').load(),
        api.dataLoader('@common.' + 'department').load(),
        api.dataLoader('@common.staffsVisibility').load(undefined, params),
        api.dataLoader('@common.departmentVisibility').load(),
      ])
    },
    'get:staffs:roleList:department:new'() {
      return Promise.all([
        api.dataLoader('@common.staffs').load(),
        api.dataLoader('@common.roleList').load(),
        api.dataLoader('@common.' + 'departmentNew').load(),
        api.dataLoader('@common.staffsVisibility').load(),
        // api.dataLoader('@common.departmentVisibility').load(),
      ])
    },

    'get:activeStaffs:roleList:department'() {
      return Promise.all([
        api.dataLoader('@common.staffsActives').load(),
        api.dataLoader('@common.roleList').load(),
        api.dataLoader('@common.department').load(),
      ])
    },
    'get:staffs:external'() {
      return Promise.all([
        api.dataLoader('@common.staffs').load(),
        api.dataLoader('@common.externalList').load(),
      ])
    },
    'get:external'() {
      return api.dispatch(actions.getExternalList())
    },
    'get:mall:role': () => {
      api.dispatch(actions.getMallRole())
    },
    'get:staffs:authorized:assist': (params) => {
      // return api.dispatch(actions.getStaffsAuthorizedAssist(params))
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getStaffsAuthorizedAssist(params))
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    'get:organizationinfo'(args) {
      api.dispatch(actions.getDepartmentTree())
      api.dispatch(actions.getStaffsAuthorized(args))
      api.dispatch(actions.getUserInfo())
      api.dispatch(actions.getExternalDepartmentTree())
      api.dispatch(actions.getExternalList())
    },
    'get:organizationInfoNew'() {
      api.dispatch(actions.getStaffsAuthorizedAssist())
      api.dispatch(actions.getUserInfo())
      api.dispatch(actions.getExternalDepartmentTree())
      api.dispatch(actions.getExternalList())
    },
    'get:externalDepartmentTree'() {
      api.dispatch(actions.getExternalDepartmentTree())
    },
    'get:department:tree'() {
      return api.dispatch(actions.getDepartmentTree())
    },
    // 获取新的部门树
    'get:department:tree:new'(params) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getDepartmentTreeNew('', '', params))
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    'get:search:department'(params) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getSearchDepartment(params))
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    'get:costCenterById'(id) {
      return api.dispatch(actions.getCostCenterById(id))
    },

    'get:staffs:blacklist'(ags) {
      return Promise.all([
        api.dispatch(actions.getStaffsAuthorized()),
        api.dispatch(actions.getStaffsActives(ags)),
      ]).then(() => {
        api.dispatch(actions.getStaffsDisabledUsers())
      })
    },
    'get:range:staffs'(params) {
      return api.dataLoader('@common.rangeStaffs').load(params)
    },

    'get:upload:token'() {
      return api.dispatch(actions.getAttachmentToken()).then((data) => data.value)
    },

    'get:staffs:byPermission'() {
      return api.dispatch(actions.getStaffsByPermission())
    },
    'get:dept:by:id'(ids) {
      return api.dispatch(actions.getDepartmentById(ids))
    },
    'get:staff:by:ids'(params) {
      return api.dispatch(actions.getStaffsByIds(params))
    },
    'get:staff:by:id'(params) {
      return api.dispatch(actions.getStaffsById(params))
    },

    'get:roles'(args) {
      return api.dispatch(actions.getRoleList()).then((state, action) => {
        const roleList = state.items
        args && args.callback && args.callback(roleList)
      })
    },

    'get:roleGroupList'(args) {
      return api.dispatch(actions.getRoleGroups()).then((state, action) => {
        const roleGroupList = state.items
        args && args.callback(roleGroupList)
      })
    },

    /**
     * 获取消费类型
     */
    'get:feeTypes'() {
      return api.dataLoader('@common.feetypes').reload()
    },

    /**
     * 获取预算信息
     */
    'get:budgetMsg'(args) {
      return api.dispatch(actions.getBudgetMsg(args.ids)).then((state, action) => {
        const items = state.items
        args && args.callback(items)
      })
    },

    /**
     * 获取提交人借款列表
     */
    'get:submitter:loan:list'(param) {
      return api.dispatch(
        actions.listLoanPackageBySubmitter({
          id: param.id,
          state: param.status,
          flowId: param.flowId,
          start: 0,
          count: 2999,
          method: param.method,
          filters: param.filters,
        }),
      )
    },

    /**
     * 获取员工可用档案
     */
    'get:staff:dimension'(params) {
      const creator = () => api.dispatch(actions.getDimensionItemsByPropertyId(params))
      const key = `get:staff:dimension@${JSON.stringify(params)}`
      return Cache.invokeCache(key, creator)
    },

    /**
     * 获取可用付款信息
     */
    'get:pay:accounts:actives'(data) {
      return api.dispatch(actions.getPayAccountList(true, data))
    },

    /**
     * 获取全部付款信息
     */
    'get:newpay:accounts'(data) {
      return api.dispatch(actions.getNewPayAccountList(data))
    },

    /**
     * 获取当前登录人可使用的支付列表
     */
    'get:pay:accounts:visibility'() {
      return api.dispatch(actions.getPayAccountsVisibilityList())
    },
    /**
     * 获取收款信息列表
     */
    'get:payeeinfos'() {
      return api.dispatch(actions.getPayees())
    },

    'get:banks'(data) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getBankList(data))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    'get:bank:cites'(data) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getCityList(data))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    'get:dimensionItems:fullName'(data) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getFullName(data))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    'get:dimensionItems:fullName:byId'(data) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getFullNameById(data))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    'get:dimensionById'(data) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getDimensionById(data))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    'get:department:by:id'(id) {
      if (!id) {
        return Promise.resolve()
      }
      return api.dispatch(actions.getDeptById(id)).then((result) => result.value)
    },
    'get:bank:provinces'(data) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getProvinceList(data))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    'get:bank:branches'(data) {
      return new Promise((resolve, reject) => {
        api
          .dispatch(actions.getBranchList(data))
          .then((res) => {
            resolve(res.items)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    'get:bank:channels'(args) {
      return api.dispatch(actions.getChannelList(args))
    },

    'get:payerInfo'() {
      return api.dispatch(actions.getPayerInfo())
    },

    'get:delegate.staffs'(delType) {
      return api.dispatch(actions.getDelegateStaffsAuthorized(delType))
    },

    'get:basedata:properties'() {
      return api.dataLoader('@common.globalFields').reload()
    },

    'get:OfficialCard'() {
      return api.dispatch(actions.getCorpOfficialCard())
    },

    'get:payee:shared'() {
      return api.dispatch(actions.payeeConfig())
    },

    'get:payer:shared'() {
      return api.dispatch(actions.payerConfig())
    },

    'refresh:permission'() {
      return api.dispatch(actions.getTotalPermission())
    },

    'get:payerlogs'(args) {
      return api.dispatch(actions.getPayeeChangeLog(args))
    },

    'get:delegate.staffs'(delType) {
      return api.dispatch(actions.getDelegateStaffsAuthorized(delType))
    },

    'get:backlog:count:approving'() {
      return delayForDispatch(api.dispatch(actions.getBackLogCount('APPROVING')))
    },

    'get:backlog:count:all'() {
      return api.dispatch(actions.getBackLogCountNew('[RECEIVING,SENDING,PAYING,APPROVING]'))
    },

    'get:backlog:count:payment'() {
      return delayForDispatch(api.dispatch(actions.getBackLogCount('PAYING')))
    },
    'get:backlog:count:receiving'() {
      return delayForDispatch(api.dispatch(actions.getBackLogCount('RECEIVING')))
    },
    'get:backlog:count:sending'() {
      return delayForDispatch(api.dispatch(actions.getBackLogCount('SENDING')))
    },
    'get:flow:info'(args) {
      return api.dispatch(actions.getFlowInfoById(args))
    },

    'get:enumitems'(args) {
      const creator = () => api.dispatch(actions.getEnumItems(args))
      const key = `get:enumitems@${JSON.stringify(args)}`
      return Cache.invokeCache(key, creator)
    },

    'get:all:currency:rate'() {
      return api.dispatch(actions.getEnterpriseAllCurrencyRate())
    },

    'get:currency:config'() {
      return api.dispatch(actions.getCurrencyConfig())
    },
    'get:field:map'(sourceTotarget) {
      return api.dispatch(actions.getFieldMaps(sourceTotarget))
    },

    'get:message:list'(param) {
      return api.dispatch(actions.messageList(param))
    },

    'update:message:status'(id) {
      return api.dispatch(actions.updateMessageStatus(id))
    },

    'get:powers'() {
      return api.dispatch(actions.getPowers()).then(async (res) => {
        // 标准版
        const standardPower = res?.items?.find((power) => power?.powerCode === '118888')
        if (standardPower?.state === 'using') {
          await api.dataLoader(`@common.standardLimtConfig`).load()
          window?.TRACK?.initAppConfig({ appVersion: 'standard_0.0.1' })
        }
        return res
      })
    },

    'fetch:baseData'() {
      api.invokeService(`@common:get:backlog:count:all`)

      api.dispatch(actions.getStandardCurrency())
      api.dispatch(actions.getAllStandardCurrency())

      api.dispatch(actions.getEnterpriseAllCurrencyRate())
      api.dispatch(actions.getEnvironment())
      api.dispatch(actions.messageList({ read: false, start: 0, count: 10 }))
      api.dispatch(actions.getCarbonCopyCount())
      api.dataLoader(`@common.department`).load()
      api.dataLoader(`@common.externalDepartment`).load()
      api.dataLoader('@common.powers').load()
      api.dataLoader('@common.waterMark').load()
      api.dataLoader('@common.warningText').load()
    },

    'get:lastChoice'(id) {
      return api.dispatch(actions.getLastChoice(id))
    },

    'download:invoce:pdf'(invoiceInfo, file) {
      const getId = (invoiceInfo) => {
        if (invoiceInfo?.id) {
          return invoiceInfo?.id
        }
        if (invoiceInfo?.form) {
          return invoiceInfo?.form?.id
        }
        return false
      }
      const id = getId(invoiceInfo)
      if (id) {
        api.invokeService('@bills:get:invoice:image:by:ids', id).then((rep) => {
          const imgInfo = rep.items[0]
          if(imgInfo && imgInfo?.fileName) {
            const params = { corpId: Fetch.ekbCorpId, key: imgInfo.fileName }
            api.invokeService('@bills:get:preview:wps', params).then(result => {
              if (result?.value?.url) {
                api.sdk.openLink(result.value?.url)
              } else {
                api.emit('@vendor:preview', imgInfo.url, imgInfo.fileName)
              }
            })
          } else {
            api.emit('@vendor:preview', imgInfo.url, imgInfo.fileName)
          }
        })
      } else {
        const previewFile = (name, url) => {
          let link = `https://doc.ekuaibao.com/view/url?url=${encodeURIComponent(url)}&name=${name}`
          const watermark = api.getState()['@common'].waterMark
          watermark && watermark !== ''
            ? (link = link + '&watermark=' + encodeURIComponent(watermark))
            : ''
          api.emit('@vendor:open:link', link)
        }
        if (file) {
          previewFile(file.fileName, file.url)
          return
        }
        Fetch.GET('/api/v1/upload/invoice/preview', {
          corpId: Fetch.ekbCorpId,
          accessToken: Fetch.accessToken,
          fpdm: invoiceInfo.fpdm,
          fphm: invoiceInfo.fphm,
        }).then((result) => {
          if (result) {
            const { name, url } = result
            previewFile(name, url)
          } else {
            showMessage.error(result.message)
          }
        })
      }
    },

    'get:CertificateTypeList'() {
      return api.dispatch(actions.getCertificateTypeList())
    },

    'get:TemporaryToken'(expireDate) {
      return api.dispatch(actions.getTemporaryToken(expireDate)).then((result) => {
        return result.value.accessToken
      })
    },

    'message:center:read:all'() {
      return api.dispatch(actions.readAllUnReadMessage())
    },

    'get:branch:by:cityid:list'(data) {
      return api.dispatch(actions.getBranchByCityIdList(data))
    },

    'dispatch:dimensionItems'(items) {
      return api.dispatch(actions.dimensionItems(items))
    },

    'import:action': () => actions,

    'get:dimension:list'(params) {
      return api.dispatch(actions.getDimensionList(params))
    },

    'get:unionBanks:list'(data) {
      return api.dispatch(actions.getUnionBanksList(data))
    },

    'get:organization:config'() {
      return api.dispatch(actions.getOrganizationConfig())
    },

    'get:visibility:staffs'(staffId) {
      const map = getVisibilityStaffsAuthorizedRequestThrottleMap
      if (map[staffId]) return map[staffId]
      const promise = api.dispatch(actions.getVisibilityStaffsAuthorized(staffId))
      promise.finally(() => {
        map[staffId] = undefined
      })
      map[staffId] = promise
      return promise
    },

    'get:visibility:department:tree'(staffId) {
      return api.dispatch(actions.getVisibilityDepartmentTree(staffId))
    },

    'get:default:payee'() {
      return api.dispatch(actions.getDefaultPayee())
    },
    'insert:assist:record'(data) {
      // 记录协助日志
      if (!data || !data.title) {
        return
      }
      const params = {
        content: data.title,
      }
      if (
        Fetch.isAssistPlatform &&
        params.content.length > 0 &&
        params.content.includes('undefined') == false
      ) {
        return api.dispatch(actions.getAssistRecode(params))
      }
      if (
        Fetch.isAssistPlatform &&
        params.content.length > 0 &&
        params.content.includes('undefined') == false
      ) {
        return api.dispatch(actions.getAssistRecode(params))
      }
    },
    //通过模块名称获取 MC 权限
    'get:mc:permission:byName'(name) {
      return api.dispatch(actions.getMCPermission(name))
    },

    'save:dimension:fullPathMap'(fullPathMap) {
      return api.dispatch(actions.saveDimensionFullPathMap(fullPathMap))
    },
    'get:space:id'() {
      return api.dataLoader('@common.spaceId').load()
    },

    'ding:pan:space:authorize'() {
      return api.dispatch(actions.dingPanAuthorize())
    },

    'ding:pan:space:authorize:download'(param) {
      return api.dispatch(actions.dingPanAuthorizeDownload(param))
    },
    'get:dimension:item:value:by:id'(params) {
      return api.dispatch(actions.getDimensionValueById(params))
    },
    'get:waterMark'() {
      return api.dispatch(actions.getWaterMark())
    },
    'get:warningText'() {
      return api.dispatch(actions.getWarningText())
    },
  },
]

function delayForDispatch(dispatch, delay = 200) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dispatch)
    }, delay)
  })
}
